code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

body {
  height: fit-content;
}

html {
  background-color: #212121;
}
